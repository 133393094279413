@use "../variables";

.image-payment-stars {
    content: url('/images/payment_stars.svg')
}

.icon-coop {
    content: url('/images/coop.svg');
}

.image-telia {
    width: 138px;
    height: 56px;
    content: url('/images/telia.svg');
}

.icon-credit {
    content: url('/images/credit.svg');
}

.icon-delivery {
    @if (variables.$site-nova) {
        content: url('/images/novastar/delivery.svg');
    }
    @else if (variables.$site-avi) {
        content: url('/images/avitela/delivery.svg');
    }
    @else {
        content: url('/images/delivery.svg');
    }
}

.icon-single-star {
    content: url('/images/single_star.svg');
}
