@use "../site";
@use "../mixins";
@use "../variables";

.centered_columns {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.checkout {
    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        background: variables.$primary;
    }

    &__phone {
        background: variables.$primary;
        font-size: variables.$font-size-xl;
        font-weight: variables.$font-weight-bold;
        position: absolute;
        top: 20px;
        right: 20px;
        background: none;
        a {
            color: variables.$white;
        }
    }

    &__wrapper {
        background-color: variables.$neutral-lighter-25;
    }
    
    .logged-out-cart button span{
        font-size: variables.$font-size-default;
    }
}

.separator{
    border-bottom: solid 1px variables.$neutral-lighter-50;
    width: 90%;
}

.button--secondary {
    border: none;
}

.bordered {
    border: solid 1px variables.$neutral-lighter-50;
    span{
        padding-right: 10px;
        padding-left: 10px;
    }
}


.iti {
    width: 100%;
}

.shipping-method-container {
    display: grid;
    align-items: start;
    column-gap: 8px;
    grid-template-columns: 8fr 1fr;
    padding: 10px;

    &.d-none {
        display: none;
    }

    &--disabled {
        pointer-events: none;
        background: variables.$neutral-lighter-50;

        * {
            color: variables.$neutral-lighter;
        }
    }
}

#checkout-payment .input-field__label {
    justify-content: flex-start;
}

.label-editable {
    max-width: 500px;
    text-align: justify;
}

#choose-store {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px 0;

    > label {
        display: flex;
        align-items: center;
        font-size: 16px;
        padding: 8px 8px 8px 0;
        color: variables.$primary;
    }
}

#choose-store-action {
    width: 310px;
}

.form-fields, .manual-address {
    place-items: flex-start;
    display: grid;
    column-gap: 8px;
    row-gap: 8px;
}

.lease__note {
    border-bottom: solid 1px variables.$primary-lighter;
    margin: 16px 0;
}

[data-typeahead-results] .action_button {
    line-height: 13px;
}

.cart-products-url {
    display: block;
    padding: 16px;
    background: variables.$white;
    font-size: 18px;

    .icon {
        color: variables.$secondary;
        display: inline;
    }
}

.overview {
    &__title {
        color: variables.$neutral;
        font-size: variables.$font-size-sm;
        margin-bottom: 16px;
        margin-top: 32px;
    }

    &__separator {
        border-top: solid 1px variables.$neutral-lighter-100;
        width: 100%;
        margin: 16px 0;

        &--bold {
            border-color: variables.$neutral-dark;
        }
    }

    &-item {
        display: grid;
        align-items: center;
        grid-template-columns: 4fr 2fr;
        margin: 8px 0;
        font-size: variables.$font-size-sm;

        .icon {
            padding: 0;
            margin-right: 5px;
            color: variables.$neutral-darker;
        }

        &__price {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            color: variables.$primary;
        }

        &__name {
            display: flex;
            flex-direction: column;
        }

        &__code {
            font-size: variables.$font-size-xs;
            color: variables.$neutral;
        }
    }

    &-total {
        font-size: variables.$font-size-default;
        font-weight: variables.$font-weight-bold;


        &__price {
            font-size: variables.$font-size-md;
            color: variables.$primary;
            font-weight: variables.$font-weight-bold;
            display: flex;
            width: 100%;
            justify-content: flex-end;
        }

        &__monthly {
            font-size: variables.$font-size-sm;
            color: variables.$neutral;
            display: flex;
            width: 100%;
            justify-content: flex-end;
        }
    }
}

[data-target="terms-modal"] {
    padding-left: 40px; // checkbox alignment
}

#giftcard-wrapper {
    margin: 0 -32px;

    .alert {
        margin: 15px auto 0 auto;
    }
}

#giftcard-paid-rows {
    display: grid;
    grid-template-columns: 4fr 5fr 3fr;
    gap: 8px;
    max-width: 515px;
    margin: 0 auto 32px auto;

    :nth-child(1), :nth-child(2) {
        color: variables.$primary;
        font-weight: variables.$font-weight-bold;
    }
}

#giftcard-fields {
    display: flex;
    justify-content: center;
    gap: 16px;

    > * {
        flex: 1;
        max-width: 250px;
    }
}

/* wide */
@media(min-width: variables.$responsive-break) {
    .checkout {
        display: grid;
        grid-template-columns: 2fr 1fr;
        column-gap: 32px;

        &__logo {
            height: 72px;
        }

        &__header {
            font-size: variables.$font-size-3xl;
            font-weight: variables.$font-weight-normal;
        }

        &__wrapper {
            min-height: 1000px;
        }

        &__overview {
            display: grid;
            grid-template-columns: 2fr 1fr;
            column-gap: 64px;
        }

        .overview {
            grid-row: 1 / span 10;
            grid-column: 2;

            &__header {
                margin: 0;
                font-size: variables.$font-size-3xl;
                color: variables.$neutral-darker;
                font-weight: variables.$font-weight-normal;
            }
        }
    }

    .cart-products-url {
        background: none;
    }

    .accordion--cart {
        margin-top: 32px;
    }

    #choose-store {
        justify-content: space-evenly;
    }
}

.cart-transport {
    &__content {
        display: none;
    }

    &__header {
        font-size: variables.$font-size-default;
        display: flex;
        justify-content: center;
        font-weight: variables.$font-weight-bold;
        color: variables.$neutral-darker;
        margin: 16px;
    }

    &__price {
        font-size: variables.$font-size-default;
        font-weight: variables.$font-weight-bold;
        text-align: right;
        white-space: nowrap;
        color: variables.$primary;
    }

    &__discount {
        display: none;

        .old-price {
            color: variables.$neutral;
            text-decoration: line-through;
            margin-right: 4px;
        }

        .price {
            font-weight: variables.$font-weight-bold;
            color: variables.$neutral-darker;
        }
    }

    .accordion__item {
        align-items: center;
        padding: 12px 0;

        .radio {
            height: initial;
            color: variables.$primary;

            &__label {
                margin: 0;
                font-weight: variables.$font-weight-bold;
                font-size: variables.$font-size-default;
                color: variables.$primary;
            }
        }

        &:not(:last-of-type) {
            border-bottom: solid 1px #f3f3f3;
        }

        p {
            font-size: variables.$font-size-sm;
        }

        &--active {
            .cart-transport__content {
                display: block;
                margin-top: 32px;
            }
        }
    }

    &__delivery-info {
        label {
            margin: 16px 0;
            display: flex;
            position: relative;
            line-height: 16px;

            .icon {
                font-size: variables.$font-size-2xl;
                padding: 0 32px;
                color: variables.$primary;
            }

            .store-details {
                color: variables.$neutral-darker;
            }
        }
    }
}

.accordion__item {
    &.locked,
    &.disabled {
        .cart-accordion__title {
            color: variables.$neutral-lighter-100;
        }
    }
}

.accordion--cart .locked .shipping-method-container {
    padding: 0;

    .radio {
        padding: 0;
    }
}

.locked {

    &:hover {

        .accordion__title,
        .accordion__content {
            background: variables.$neutral-lighter-10;
        }

        .input-field__input,
        .tabs-menu__item {
            background: variables.$neutral-lighter-10;
        }
    }

    .contact-form {
        margin-top: 24px;
    }

    .dropdown {
        padding: 0;
        border: 0;

        &__selected {
            color: variables.$neutral-dark;
            font-size: variables.$font-size-default;
            padding-left: 0px;
        }

        &__arrow {
            display: none;
        }
    }

    .input-field {
        &__label,
        &__input {
            color: variables.$neutral-dark;
            border: 0;
            padding: 0;

            &-container span {
                color: variables.$neutral-dark;
                cursor: text;
            }
        }

        &-editable {
            .textarea__input {
                padding: 0;
                border: 0;
                color: variables.$neutral-dark;
            }

            @include mixins.input-field-editable;
        }
    }

    .changeFields {
        display: block;
    }

    &:hover {

        .input-field > :hover {
            cursor: pointer;
        }

        .changeFields {

            .icon {
                color: variables.$primary;
            }
        }
    }

    .address-manually,
    .iti__flag-container,
    .submitForm,
    .openModal,
    #addNote,
    .label-editable,
    #choose-store label,
    .radio .checkmark {
        display: none;
    }

    .cart-transport {
        &__delivery-info {
            .radio {
                padding: 0;
            }

            .icon {
                padding-left: 0;
            }
        }

        .accordion__item {
            color: variables.$neutral;
            border-bottom: none;

            &--active {
                .radio {
                    &__label {
                        color: variables.$neutral;
                    }
                }

                .cart-transport__content {
                    margin-top: 0;
                }
            }

            &:not(.accordion__item--active) {
                display: none;
            }
        }

        &__content .icon,
        p,
        .store-details,
        .selected-store {
            color: variables.$neutral;
        }

        .radio__label,
        .cart-transport__price {
            font-weight: variables.$font-weight-normal;
        }

        .selected-store {
            font-weight: variables.$font-weight-bold;
        }

        .dropdown__selected {
            cursor: text;
            padding: 0;
        }
    }

    .text-success, .cart-transport__price {
        color: variables.$neutral;
    }

    .readonly-transport {
        display: grid;
        grid-template-columns: 3fr 1fr;
        justify-items: start;
    }

    label > span {
        line-height: 24px;
    }

    .action_button {
        margin: 0;
        border: none;
        padding-left: 0;

        &__icon {
            display: none;
        }
    }

    .iti--allow-dropdown input {
        padding: 0;
    }
}

.accordion--cart {
    .input-field {
        &__label {
            color: variables.$neutral-darker;
            font-size: variables.$font-size-default;
            padding-left: 0;
            padding-right: 0;
            display: flex;
            justify-content: flex-end;
            text-align: right;
            align-items: center;
        }
    }

    .shipping-method-container .radio {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        row-gap: 8px;
        padding-left: 32px;
        justify-items: start;
        align-items: center;
    }

    .accordion__item--active {

        .cart-accordion__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 16px 16px;
        }
    }

    .cart-accordion__item {
        position: relative;
        overflow: visible;

        &:last-of-type {
            margin-bottom: 40px;
        }
    }

    .cart-accordion__item.disabled {
        pointer-events: none;
    }

    .cart-accordion__item.disabled,
    .cart-accordion__item.locked {
        margin: 16px;
    }

    .cart-accordion {

        &__title {
            background-color: variables.$white;
            font-weight: variables.$font-weight-bold;
            font-size: variables.$font-size-md;
            color: variables.$primary;
            padding: 26px 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__content,
        &__content .radio-tabs {
            background: variables.$white;
        }
    }

    .cart-address > * {
        font-size: variables.$font-size-default;
    }

    .legal-person-info {
        display: flex;
        justify-content: center;
        padding-top: 24px;
        color: variables.$neutral;
    }
}

/* mobile */
@media(max-width: variables.$mobile-break - 1) {
    .centered_columns {
        flex-basis: 100%;
    }
}

/* small mobile */
@media(max-width: variables.$small-mobile-break - 1) {
    .form-fields, .manual-address {
        display: flex;
        flex-direction: column;
        min-width: 250px;
    }

    .accordion--cart .input-field__label {
        justify-content: flex-start;
    }
}

/* narrow */
@media(max-width: variables.$responsive-break - 1) {
    .checkout {
        display: flex;
        flex-direction: column;

        &__header {
            display: none;
        }

        &__logo {
            height: 56px;

            .logo-brand {
                width: 150px;
            }
        }

        &__overview {
            display: flex;
            flex-direction: column;
        }

        .overview {
            background: variables.$white;

            &__content {
                margin: 16px;
                padding: 16px 0;
                background: variables.$neutral-lighter-50;
                border-radius: 4px;
            }

            &__header, &__order {
                text-align: center;
                margin: 16px;
            }

            &__header {
                font-size: variables.$font-size-lg;
            }

            &__order {
                font-size: variables.$font-size-md;
            }

            &__title, &__separator {
                display: none;
            }

            &-item {
                margin: 0;
                padding: 16px;
                background: variables.$neutral-lighter-25;
                border-top: 1px solid variables.$neutral-lighter;
                justify-content: space-between;

                &:last-child {
                    background: variables.$neutral-lighter-50;
                }

                &__price {
                    white-space: nowrap;
                }
            }
        }
        
        .register.flex-center {
            flex-direction: column;
            text-align: center;
        }
        .cart-accordion__title{
            padding: 26px 16px;
        }
    }

    [data-shipping-method-container] {
        max-width: 600px;
        width: 100%;
    }

    #choose-store {
        justify-content: flex-start;
    }
}

@media(min-width: variables.$responsive-break) {
    .overview__content {
        position: fixed;
        padding-right: 32px;
        top: 100px;
        bottom: 60px;
        overflow: auto;
        scrollbar-width: none;
    }

    .overview__content::-webkit-scrollbar {
        display: none;
    }
    .cart-transport{
        max-width: 500px;
        margin: 0 auto;
    }
}

@media(max-width: variables.$responsive-break) {
    .checkout {
        &__phone {
            display: flex;
            align-items: baseline;
            justify-content: center;
            align-content: center;
            background: variables.$primary;
            font-size: 16px;
            font-weight: 700;
            position: inherit;
            padding-bottom: 10px;
        }
    }
    .icon-phone {
        width: 17px;
        padding-right: 4px;
    }
}
@if variables.$site-nova {
    .checkout__logo {
        background: url('/images/novastar/background_no_stars.png');
    }
}