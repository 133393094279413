@use "../variables";
@use "../mixins";

#checkout-payment {
    max-width: variables.$mobile-break;
    margin: auto;
}

#bigbank-form {
    h1 {
        text-align: center;
        padding: 15px 0;
    }
}

#bigbank-form {
    h3 {
        padding-top: 15px;
    }
}

.lease-banner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 32px;
}

.bank-links, .other-payments {
    display: flex;
    flex-wrap: wrap;

    &__item {
        margin: 20px;
    }
}

.payment-footer {
    display: none;
}

.accordion--cart .accordion__item--active .payment-footer {
    display: block;
    background-color: variables.$neutral-lighter-25;
    padding: 20px;
    border-radius: variables.$border-radius-rounder;
}

#everypay-frame {
    border: none;
    height: 500px;
    width: 100%;
}

#ContractDetailsSupplementaryFieldsModel_MaximumCreditLimit {
    width: 0;
    height: 0;
    visibility: hidden;
}

.lease-form {
    font-size: variables.$font-size-default;

    .terms-checkbox {
        margin-left: 20px;
    }
}

.lease-terms {
    & > * {
        margin-top: 16px;
    }
}

.bigbank-contract-radio {
    margin-bottom: 3em;
    display: none;

    &--active {
        display: block;
    }

    .radio-header {
        margin-bottom: 1em;
    }
}

.money-transfer-description, .on-receiving-description {
    display: none;
    
    &--active {
        display: block;
    }
}

.credit-card-accordion-text{
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
}

#google-pay-button{
    background: variables.$black;
}

#smart-rent-container{
    color: variables.$neutral-dark;
    @include mixins.rental-periods;
}


/* full view */
@media (min-width: variables.$mobile-break) {
    .accordion--cart .accordion__item--active .payment-footer {
        margin: 32px 64px;
    }
}

/* mobile */
@media (max-width: variables.$mobile-break - 1) {
    .lease-banner {
        display: none;
    }
    .credit-card-accordion-text {
        justify-content: flex-start;
    }
}
